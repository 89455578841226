import '../styles/press-release.css';

import React from 'react';

import Layout from '../components/layout';
import HDImg4 from '../images/Benzinga-logo-navy.svg';
import HDImg10 from '../images/cropped-Corporatewire-e1596487547637.png';
import HDImg8 from '../images/cropped-family-e1639314714348.png';
import HDImg7 from '../images/cropped-insurance-1-e1597076379826-1.png';
import HDImg9 from '../images/cropped-Investment-Newswire-logo-e1597094184178.png';
import HDImg6 from '../images/inn-logo.png';
import HDImg12 from '../images/logo@2x.png';
import HDImg3 from '../images/logo-finanzen-net.svg';
import HDImg5 from '../images/morningstar-50px.d304f16.svg';
import HDImg from '../images/yahoo_finance_en-US_h_p_financev2.png';
import HDImg1 from '../images/yahoo_finance_en-US_h_p_financev2.png';
import HDImg2 from '../images/yahoo_finance_en-US_h_p_financev2.png';
import HDImg11 from '../images/yahoo_finance_en-US_h_p_financev2.png';


const PressRelease = () => {
  return (
    <Layout>
      <div className="paddingt-90 container-fluid">

        <div className="row">
          <div className="col-lg-12" style={{ display:'flex',justifyContent:'space-around',flexDirection:'column',alignItems:'center' }}>
            <h1 className="headings">#news</h1>

            <p> For all press and speaker inquiries, please email press@getmybubble.com</p>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 " style={{ display:'flex',flexWrap:'wrap',justifyContent:'space-around' }}>

            <div className="cardstyle"  >
              <img src={HDImg} className="image-style"  alt=""/>

              <div className="empty">
                <h4><b>Bubble Launches Online Embedded Insurance for Housing Industry in the Wake of $1.75M in Funding</b></h4>

                <a href='https://finance.yahoo.com/news/bubble-launches-online-embedded-insurance-140500838.html' rel="noreferrer" target='_blank'>
                  <button style={{ borderRadius:'27.5px',padding:'10px 35px',backgroundColor:'#ff7007' }}>Read More</button>
                </a>
              </div>
            </div>

            <div className="cardstyle"  >
              <img src={HDImg1}  className="image-style" alt=""/>

              <div className="empty">
                <h4><b>Bubble Launches Online Embedded Insurance for Housing Industry in the Wake of $1.75M in Funding</b></h4>

                <a href='https://www.marketwatch.com/press-release/bubble-launches-online-embedded-insurance-for-housing-industry-in-the-wake-of-175m-in-funding-2022-01-13' rel="noreferrer" target='_blank'>
                  <button style={{ borderRadius:'27.5px',padding:'10px 35px',backgroundColor:'#ff7007' }}>Read More</button>
                </a>
              </div>
            </div>

            <div className="cardstyle"  >
              <img src={HDImg2}  className="image-style" alt=""/>

              <div className="empty">
                <h4><b>Bubble Launches Online Embedded Insurance for Housing Industry in the Wake of $1.75M in Funding</b></h4>

                <a href='https://seekingalpha.com/pr/18630154-bubble-launches-online-embedded-insurance-for-housing-industry-in-wake-of-1_75m-in-funding' rel="noreferrer" target='_blank'>
                  <button style={{ borderRadius:'27.5px',padding:'10px 35px',backgroundColor:'#ff7007' }}>Read More</button>
                </a>
              </div>
            </div>

            <div className="cardstyle" >
              <img src={HDImg3} className="image-style" alt=""/>

              <div className="empty">
                <h4><b>Bubble Launches Online Embedded Insurance for Housing Industry in the Wake of $1.75M in Funding</b></h4>

                <a href='https://www.finanzen.net/nachricht/aktien/bubble-launches-online-embedded-insurance-for-housing-industry-in-the-wake-of-1-75m-in-funding-10922839' rel="noreferrer" target='_blank'>
                  <button style={{ borderRadius:'27.5px',padding:'10px 35px',backgroundColor:'#ff7007' }}>Read More</button>
                </a>
              </div>
            </div>

            <div className="cardstyle"  >
              <img src={HDImg4} className="image-style" alt=""/>

              <div className="empty">
                <h4><b>Bubble Launches Online Embedded Insurance for Housing Industry in the Wake of $1.75M in Funding</b></h4>

                <a href='https://www.benzinga.com/pressreleases/22/01/n25037057/bubble-launches-online-embedded-insurance-for-housing-industry-in-the-wake-of-1-75m-in-funding' rel="noreferrer" target='_blank'>
                  <button style={{ borderRadius:'27.5px',padding:'10px 35px',backgroundColor:'#ff7007' }}>Read More</button>
                </a>
              </div>
            </div>

            <div className="cardstyle"  >
              <img src={HDImg5}  className="image-style" alt=""/>

              <div className="empty">
                <h4><b>Bubble Launches Online Embedded Insurance for Housing Industry in the Wake of $1.75M in Funding</b></h4>

                <a href='https://www.morningstar.com/news/pr-newswire/20220113ph29476/bubble-launches-online-embedded-insurance-for-housing-industry-in-the-wake-of-175m-in-funding' rel="noreferrer" target='_blank'>
                  <button style={{ borderRadius:'27.5px',padding:'10px 35px',backgroundColor:'#ff7007' }}>Read More</button>
                </a>
              </div>
            </div>

            <div className="cardstyle"  >
              <img src={HDImg6}  className="image-style" alt=""/>

              <div className="empty">
                <h4><b>Bubble Launches Online Embedded Insurance for Housing Industry in the Wake of $1.75M in Funding</b></h4>

                <a href='https://insurancenewsnet.com/oarticle/bubble-launches-online-embedded-insurance-for-housing-industry-in-the-wake-of-1-75m-in-funding' rel="noreferrer" target='_blank'>
                  <button style={{ borderRadius:'27.5px',padding:'10px 35px',backgroundColor:'#ff7007' }}>Read More</button>
                </a>
              </div>
            </div>

            <div className="cardstyle"  >
              <img src={HDImg7}  className="image-style" alt=""/>

              <div className="empty">
                <h4><b>Bubble Launches Online Embedded Insurance for Housing Industry in the Wake of $1.75M in Funding</b></h4>

                <a href='https://insurancenewswire.com/press-release/bubble-launches-online-embedded-insurance-for-housing-industry-in-the-wake-of-1-75m-in-funding-301459733/' rel="noreferrer" target='_blank'>
                  <button style={{ borderRadius:'27.5px',padding:'10px 35px',backgroundColor:'#ff7007' }}>Read More</button>
                </a>
              </div>
            </div>

            <div className="cardstyle"  >
              <img src={HDImg8}  className="image-style" alt=""/>

              <div className="empty">
                <h4><b>Bubble Launches Online Embedded Insurance for Housing Industry in the Wake of $1.75M in Funding</b></h4>

                <a href='https://un-insurance.com/bubble-launches-online-embedded-insurance-for-housing-industry-in-the-wake-of-1-75m-in-funding/' rel="noreferrer" target='_blank'>
                  <button style={{ borderRadius:'27.5px',padding:'10px 35px',backgroundColor:'#ff7007' }}>Read More</button>
                </a>
              </div>
            </div>

            <div className="cardstyle"  >
              <img src={HDImg9}  className="image-style" alt=""/>

              <div className="empty">
                <h4><b>Bubble Launches Online Embedded Insurance for Housing Industry in the Wake of $1.75M in Funding</b></h4>

                <a href='https://investmentnewswire.com/press-release/bubble-launches-online-embedded-insurance-for-housing-industry-in-the-wake-of-1-75m-in-funding-301459733/' rel="noreferrer" target='_blank'>
                  <button style={{ borderRadius:'27.5px',padding:'10px 35px',backgroundColor:'#ff7007' }}>Read More</button>
                </a>
              </div>
            </div>

            <div className="cardstyle" >
              <img src={HDImg10}  className="image-style" alt=""/>

              <div className="empty">
                <h4><b>Bubble Launches Online Embedded Insurance for Housing Industry in the Wake of $1.75M in Funding</b></h4>

                <a href='https://corporatewire.com/press-release/bubble-launches-online-embedded-insurance-for-housing-industry-in-the-wake-of-1-75m-in-funding-301459733/' rel="noreferrer" target='_blank'>
                  <button style={{ borderRadius:'27.5px',padding:'10px 35px',backgroundColor:'#ff7007' }}>Read More</button>
                </a>
              </div>
            </div>

            <div className="cardstyle"  >
              <img src={HDImg11}  className="image-style" alt=""/>

              <div className="empty">
                <h4><b>Bubble Launches Online Embedded Insurance for Housing Industry in the Wake of $1.75M in Funding</b></h4>

                <a href='https://www.newsbreak.com/news/2484819076271/bubble-launches-online-embedded-insurance-for-housing-industry-in-the-wake-of-1-75m-in-funding' rel="noreferrer" target='_blank'>
                  <button style={{ borderRadius:'27.5px',padding:'10px 35px',backgroundColor:'#ff7007' }}>Read More</button>
                </a>
              </div>
            </div>

            <div className="cardstyle"  >
              <img src={HDImg12}  className="image-style" alt=""/>

              <div className="empty">
                <h4><b>Bubble Launches Online Embedded Insurance for Housing Industry in the Wake of $1.75M in Funding</b></h4>

                <a href='https://www.dailyadvent.com/news/409406ea765dd36fead2abfad39857b0-Bubble-Launches-Online-Embedded-Insurance-for-Housing-Industry-in-the-Wake-of-175M-in-Funding' rel="noreferrer" target='_blank'>
                  <button style={{ borderRadius:'27.5px',padding:'10px 35px',backgroundColor:'#ff7007' }}>Read More</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PressRelease;
